<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card" :class="$style.container">
				<div class="text-dark font-size-24 mb-4">
					<strong>Recuperación de contraseña</strong>
				</div>
				<a-form class="mb-4" :form="form" @submit="handleSubmit">
					<a-form-item label="Contraseña">
						<a-input-password
							placeholder="Ingresa nueva contraseña"
							v-decorator="[
								'password',
								{
									rules: [
										{ required: true, message: 'Campo requerido.' },
										{
											min: 6,
											message: 'Ingrese contraseña de al menos 6 caracteres.',
										},
										{
											validator: validateToNextPassword,
										},
									],
								},
							]"
							autocomplete="off"
						/>
					</a-form-item>
					<a-form-item label="Confirma tu contraseña">
						<a-input-password
							placeholder="Confirma tu nueva contraseña"
							@blur="handleConfirmBlur"
							v-decorator="[
								'confirmPassword',
								{
									rules: [
										{ required: true, message: 'Campo requerido.' },
										{
											min: 6,
											message: 'Ingrese contraseña de al menos 6 caracteres.',
										},
										{
											validator: compareToFirstPassword,
										},
									],
								},
							]"
							autocomplete="off"
						/>
					</a-form-item>
					<a-button size="large" type="primary" htmlType="submit" class="text-center w-100">
						<strong>Enviar</strong>
					</a-button>
				</a-form>
				<router-link to="/auth/login" class="kit__utils__link font-size-16">
					<i class="fe fe-arrow-left mr-1 align-middle" />
					Regresar a inicio de sesión
				</router-link>
			</div>
		</a-spin>
	</div>
</template>
<script>
import utilities from '@/services/utilities'

export default {
	name: 'RecoveryPassword',
	computed: {
		spinnerLoader() {
			return this.$store.state.user.loading
		},
		spinnerLoaderLabel() {
			return this.$store.state.user.spinnerLoaderLabel || 'Espere por favor...'
		},
		hasToken() {
			return utilities.objectValidate(this.$router.currentRoute.query, 'token', false) ? this.$router.currentRoute.query.token : false
		},
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
			confirmDirty: false,
		}
	},
	mounted() {
		if (!this.hasToken) {
			this.$router.push('/auth/login')
		}
	},
	methods: {
		// Personal Information
		handleConfirmBlur(e) {
			const value = e.target.value
			this.confirmDirty = this.confirmDirty || !!value
		},
		compareToFirstPassword(rule, value, callback) {
			if (value && value !== this.form.getFieldValue('password')) {
				callback('Las contraseñas deben coincidir.')
			} else {
				callback()
			}
		},
		validateToNextPassword(rule, value, callback) {
			if (value && this.confirmDirty) {
				this.form.validateFields(['confirmPassword'], { force: true })
			}
			callback()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					let payload = {
						...values,
						token: this.$router.currentRoute.query.token,
					}
					this.$store.dispatch('user/UPDATE_PASSWORD', { payload })
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
