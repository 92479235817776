<template>
	<div>
		<recovery-password />
	</div>
</template>
<script>
import RecoveryPassword from '@/components/cleanui/system/Auth/RecoveryPassword'
export default {
	components: {
		RecoveryPassword,
	},
}
</script>
